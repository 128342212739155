<template>
  <div>
    <div class="statement-details--title">
      <el-button type="primary" style="float:right" :loading="submitLoading" @click="_submit">确认提交</el-button>
      <h3>供应商对账单（赔偿）</h3>
    </div>
    <vxe-table
      ref="xTable"
      max-height="500px"
      border
      :data="vendorBillTableData"
      align="center"
      keep-source
      show-footer-overflow
      show-footer
      :footer-method="footerMethod"
      class="list"
      :edit-rules="validRules"
      :edit-config=" {trigger: 'click', mode: 'cell'}"
      :cell-style="cellStyle"
    >
      <vxe-table-column type="seq" width="60" title="序号" />
      <vxe-table-column field="estimationPayableCode" title="暂估应付单" min-width="180" />
      <vxe-table-column field="vendorName" title="供应商" min-width="80" />
      <vxe-table-column field="inputAmount" title="录入金额" min-width="100" />
      <vxe-table-column field="inputCurrency" title="录入币种" min-width="80" />
      <vxe-table-column field="cargoOwnerCode" title="结算主体" min-width="100" :edit-render="{}">
        <template #default="{ row }">
          <span>{{ row.cargoOwnerName }}</span>
        </template>
        <template #edit="{ row }">
          <vxe-select v-model="row.cargoOwnerCode" transfer @change="handleCargoOwnerCodeChange(row)">
            <vxe-option v-for="item in cargoOwnerNameList" :key="item.cargoOwnerName" :value="item.cargoOwnerCode" :label="item.cargoOwnerName" />
          </vxe-select>
        </template>
      </vxe-table-column>
      <vxe-table-column field="currency" title="结算币种" min-width="80" :edit-render="{}">
        <template #edit="{ row }">
          <vxe-select v-model="row.currency" transfer @change="handleCurrencyChange(row)">
            <vxe-option v-for="item in currencyList" :key="item" :label="item" :value="item" />
          </vxe-select>
        </template>
      </vxe-table-column>
      <vxe-table-column field="settlementRate" min-width="100" :edit-render="{}">
        <template #header>
          <span>结算汇率</span>
          <el-tooltip class="item" effect="dark" placement="top">
            <i class="el-icon-question" style="font-size: 14px; vertical-align: middle;" />
            <div slot="content">
              <p>请填写“结算币种”兑“录入币种”的汇率</p>
            </div>
          </el-tooltip>
        </template>
        <template #default="{ row }">
          <span>{{ row.settlementRate === 0 ? '0.0000' : row.settlementRate ? Number(row.settlementRate).toFixed(4) : null }}</span>
        </template>
        <template #edit="{ row }">
          <el-input-number
            v-model="row.settlementRate"
            :disabled="row.inputCurrency === row.currency"
            :controls="false"
            :precision="4"
            style="width: 100%"
            @change="handleSettlementRateChange(row)"
          />
        </template>
      </vxe-table-column>
      <vxe-table-column field="totalPrice" title="对账金额" min-width="100" />
      <vxe-table-column field="taxRatio" title="税率%" min-width="80" />
      <vxe-table-column field="ifInvoice" title="是否开票" min-width="80">
        <template #default="{row}">
          {{ row.ifInvoice === 1 ? '是' : '否' }}
        </template>
      </vxe-table-column>
      <vxe-table-column field="invoiceAmount" title="开票金额" min-width="100" />
      <vxe-table-column field="noInvoiceAmount" title="不开票金额" min-width="100">
        <template #default="{row}">
          {{ row.noInvoiceAmount || 0 }}
        </template>
      </vxe-table-column>
      <vxe-table-column field="remark" title="操作" min-width="120">
        <template #default="{ row, rowIndex }">
          <el-button v-if="row.totalPrice < 0" type="text" :loading="row.loading" @click="handleConfirm(row, rowIndex)">
            确认开票金额
          </el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <el-dialog
      title="确认开票金额"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="editForm" :model="currentRow" label-width="120px">
        <el-row class="row-flexed">
          <el-col :span="12">
            <el-form-item label="录入金额：">
              <el-input-number
                v-model="currentRow.inputAmount"
                controls-position="right"
                :precision="2"
                disabled
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="录入币种：">
              <el-input v-model="currentRow.inputCurrency" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-flexed">
          <el-col :span="12">
            <el-form-item label="对账金额：">
              <el-input-number
                v-model="currentRow.totalPrice"
                controls-position="right"
                :precision="2"
                disabled
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结算币种：" required>
              <el-input v-model="currentRow.currency" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否开票：" required>
              <el-select v-model="currentRow.ifInvoice" style="width:100%" @change="updateInvoiceStatus">
                <el-option :value="1" label="是" />
                <el-option :value="2" label="否" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开票金额：" required>
              <el-input-number
                v-model="currentRow.invoiceAmount"
                controls-position="right"
                :disabled="currentRow.ifInvoice === 2"
                :min="currentRow.totalPrice"
                :max="0"
                :precision="2"
                style="width: 100%"
                @change="updateAmount"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="不开票金额：">
              <el-input-number
                v-model="currentRow.noInvoiceAmount"
                controls-position="right"
                :precision="2"
                disabled
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row v-if="currentRow.ifInvoice === 1" style="margin-top:20px">
        <el-row :gutter="10" style="display:flex;align-items:center">
          <el-col :span="15" style="text-align: right">PO汇总金额：</el-col>
          <el-col :span="6">
            <el-input-number
              v-model="poTotalAmount"
              controls-position="right"
              :precision="2"
              style="width: 100%"
              disabled
            />
          </el-col>
        </el-row>
        <el-row
          v-for="(item, index) in poDataList"
          :key="(index + item.purchaseOrderCode)"
          :gutter="10"
          style="display:flex;align-items:center;margin-top:10px"
        >
          <el-col :span="3" class="el-form-item__label">选择PO：</el-col>
          <el-col :span="6">
            <el-select
              :ref="('code'+index)"
              v-model="item.purchaseOrderCode"
              filterable
              style="width:100%"
              @change="updateOrderPaymentAmount($event, item)"
            >
              <el-option
                v-for="order in currentRow.poOptionList"
                :key="order.purchaseOrderCode"
                :value="order.purchaseOrderCode"
                :label="order.purchaseOrderCode"
                :disabled="order.disabled"
              />
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-input v-model="item.paymentAmount" disabled />
          </el-col>
          <el-col :span="6">
            <el-input-number
              :ref="('amount'+index)"
              v-model="item.offsetAmount"
              controls-position="right"
              :precision="2"
              :max="0"
              style="width: 100%"
              placeholder="请输入"
              @change="updateAmount"
            />
          </el-col>
          <el-col :span="3">
            <i
              v-if="(index > 0)"
              class="el-icon-minus"
              style="color: #1890ff;font-size: 20px;cursor: pointer;"
              @click="editPODataList(index)"
            />
            <i
              v-if="(index === poDataList.length - 1) && (poDataList.length < 10)"
              class="el-icon-plus"
              style="color: #1890ff;font-size: 20px;cursor: pointer;margin-left:10px;"
              @click="editPODataList()"
            />
          </el-col>
        </el-row>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveInvoiceByRow">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { isJson } from '@/utils'
import XEUtils from 'xe-utils'
import { deepClone } from '@/utils'
import { FloatSub, FloatAdd } from '@/utils/acc.js'
import { pick } from 'lodash'
import { indemnitySubmitView, getPoNoReconcileAmountList, indemnitySubmit, getAllCargoOwnerList, getSettlementRateByInputSettlementCurrency, getCurrencyListByVendorId } from '@/api/vendorPurchase-api.js'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
export default {
  mixins: [commodityInfoDict],
  data() {
    const rateValid = ({ cellValue }) => {
      if (cellValue === undefined || cellValue <= 0) {
        return new Error('结算汇率必须大于0')
      }
    }
    return {
      vendorBillTableData: [],
      vendorBillTableBackupData: [],
      dialogVisible: false,
      rowIndex: 0,
      currentRow: {
        currency: null,
        totalPrice: null,
        ifInvoice: 1,
        poOptionList: []
      },
      poDataList: [],
      vendorId: null,
      submitLoading: false,
      cargoOwnerNameList: [],
      validRules: {
        settlementRate: [
          { validator: rateValid }
        ]
      },
      currencyList: []
    }
  },
  computed: {
    routeQueryForm() {
      return isJson(this.$route.query.form) ? JSON.parse(this.$route.query.form) : {}
    },
    poTotalAmount: {
      get() {
        if (this.poDataList.length) {
          return this.poDataList.reduce((prev, current) => Number(FloatAdd(prev, current.offsetAmount || 0)), 0) || 0
        } else {
          return 0
        }
      },
      set(value) {
        return value
      }
    }
  },
  watch: {
    poDataList: {
      handler(newValue) {
        const poList = new Set(newValue.map(v => v.purchaseOrderCode))
        this.currentRow.poOptionList.forEach(v => {
          v.disabled = poList.has(v.purchaseOrderCode)
        })
      },
      deep: true
    }
  },
  async created() {
    this.vendorId = Number(this.$route.query.vendorId)
    const { datas } = await indemnitySubmitView({
      ...this.routeQueryForm,
      vendorId: this.vendorId
    })
    this._getcargoownerList()
    this.vendorBillTableData = datas
    this.vendorBillTableData.map(data => {
      data.ifInvoice = 1
      data.invoiceAmount = data.totalPrice
      data.noInvoiceAmount = 0
      data.settlementRate = data.settlementRate === null ? undefined : data.settlementRate
    })
    this.vendorBillTableBackupData = deepClone(this.vendorBillTableData) // 对比差异，设置单元格颜色
    const { datas: currencyData } = await getCurrencyListByVendorId(this.vendorId)
    this.currencyList = currencyData
  },
  methods: {
    async _getcargoownerList() {
      const { datas } = await getAllCargoOwnerList()
      this.cargoOwnerNameList = datas.filter(v => v.excludingTaxRate !== null || v.excludingTaxRate !== undefined)
    },
    handleCargoOwnerCodeChange(row) {
      const target = this.cargoOwnerNameList.filter(item => item.cargoOwnerCode === row.cargoOwnerCode)?.[0] || {}
      row.cargoOwnerName = target.cargoOwnerName
      row.taxRatio = Number(((target.excludingTaxRate - 1) * 100).toFixed(0))
      this.resetAmountAndPoList(row)
    },
    async handleCurrencyChange(row) {
      const { datas } = await getSettlementRateByInputSettlementCurrency({
        inputCurrency: row.inputCurrency,
        settlementCurrency: row.currency
      })
      row.settlementRate = Number(datas)
      this.handleAmountChange(row)
      this.resetAmountAndPoList(row)
    },
    handleSettlementRateChange(row) {
      this.handleAmountChange(row)
      this.resetAmountAndPoList(row)
    },
    handleAmountChange(row) {
      row.totalPrice = row.settlementRate === undefined ? null : Math.round(row.inputAmount * row.settlementRate * 100) / 100
    },
    resetAmountAndPoList(row) {
      row.invoiceAmount = row.totalPrice
      row.noInvoiceAmount = 0
      row.outBillDetailIndemnitySubmitRequestDTOList = []
    },
    cellStyle(cell) {
      const columns = { 5: 'cargoOwnerCode', 6: 'currency', 7: 'settlementRate', 8: 'totalPrice' }
      if (columns[cell.columnIndex]) {
        const field = columns[cell.columnIndex]
        const target = this.vendorBillTableBackupData.filter((v) => v.estimationPayableCode === cell.row.estimationPayableCode)?.[0] || {}
        if (cell.row[field] !== target[field]) {
          return {
            background: 'yellow'
          }
        }
      }
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['totalPrice', 'invoiceAmount', 'noInvoiceAmount'].includes(column.property)) {
            return XEUtils.sum(data, column.property)
          }
          return ''
        })
      ]
    },
    async handleConfirm(row, index) {
      try {
        this.$set(row, 'loading', true)
        const { datas } = await getPoNoReconcileAmountList({
          cargoOwnerCode: row.cargoOwnerCode,
          currentCode: row.currency,
          taxRatio: row.taxRatio,
          vendorId: this.vendorId
        })
        this.rowIndex = index
        this.currentRow = Object.assign({}, row, { poOptionList: datas })
        this.poDataList = row.outBillDetailIndemnitySubmitRequestDTOList || []
        if (this.poDataList.length === 0) {
          this.editPODataList()
        }
        this.dialogVisible = true
      } finally {
        this.$set(row, 'loading', false)
      }
    },
    updateInvoiceStatus(value) {
      this.currentRow.invoiceAmount = value === 1 ? this.currentRow.totalPrice : 0
      this.updateAmount()
      this.poDataList = []
      this.editPODataList()
    },
    updateAmount() {
      this.currentRow.noInvoiceAmount = Number(FloatSub(this.currentRow.totalPrice, this.currentRow.invoiceAmount))
    },
    updateOrderPaymentAmount(value, target) {
      target.paymentAmount = this.currentRow.poOptionList.find(v => v.purchaseOrderCode === value).paymentAmount
    },
    editPODataList(index) {
      if (index === undefined) {
        this.poDataList.push({
          purchaseOrderCode: null,
          paymentAmount: null,
          offsetAmount: null,
          estimationPayableCode: this.currentRow.estimationPayableCode
        })
      } else {
        this.poDataList.splice(index, 1)
      }
    },
    saveInvoiceByRow() {
      if (this.currentRow.ifInvoice === 1) {
        // 开票金额须等于PO汇总金额，且需验证每条PO单号已选，PO开票金额不等于0
        if (this.currentRow.invoiceAmount === this.poTotalAmount) {
          const result = this.poDataList.every((v, index) => {
            if (!v.purchaseOrderCode) {
              this.$message.warning('请选择PO单号')
              this.$refs['code' + index][0].focus()
              return false
            } else if (!v.offsetAmount) {
              this.$message.warning('PO开票金额不能为0')
              this.$refs['amount' + index][0].focus()
              return false
            }
            return true
          })
          result && this.updateTableData()
        } else {
          this.$message.warning('PO汇总金额不等于开票金额，无法保存')
        }
      } else {
        this.updateTableData()
      }
    },
    updateTableData() {
      this.$set(this.vendorBillTableData, this.rowIndex, Object.assign(this.vendorBillTableData[this.rowIndex], {
        ifInvoice: this.currentRow.ifInvoice,
        invoiceAmount: this.currentRow.invoiceAmount,
        noInvoiceAmount: this.currentRow.noInvoiceAmount,
        outBillDetailIndemnitySubmitRequestDTOList: this.currentRow.ifInvoice === 1 ? deepClone(this.poDataList) : []
      }))
      this.poDataList = []
      this.currentRow = {
        currency: null,
        totalPrice: null,
        ifInvoice: 1,
        poOptionList: []
      }
      this.row = null
      this.dialogVisible = false
    },
    poDataListValidate() {
      const inValidList = this.poDataList.filter(v => !v.purchaseOrderCode || !v.offsetAmount)
      return inValidList.length
    },
    async _submit() {
      const errMap = await this.$refs.xTable.validate(true).catch(errMap => errMap)
      if (errMap) {
        this.$message.warning('结算汇率必须大于0')
        return
      }
      const data = this.vendorBillTableData.filter(v => v.ifInvoice === 1 && v.invoiceAmount < 0 && !v.outBillDetailIndemnitySubmitRequestDTOList)
      if (data.length) {
        this.$message.warning('存在暂估未确认开票金额')
        return
      }
      try {
        this.submitLoading = true
        const indemnityOutBillSubmitRequestDTOList = this.vendorBillTableData.map(v => {
          return pick(v, [
            'estimationPayableCode',
            'ifInvoice',
            'invoiceAmount',
            'noInvoiceAmount',
            'outBillDetailIndemnitySubmitRequestDTOList',
            'cargoOwnerCode',
            'cargoOwnerName',
            'currency',
            'settlementRate',
            'totalPrice'
          ])
        })
        const { datas } = await indemnitySubmit({ indemnityOutBillSubmitRequestDTOList, vendorId: this.vendorId })
        if (datas) {
          this.$message.success('提交成功')
          this.$router.go(-1)
        }
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
/deep/ .el-table {
  display: flex;
  flex-direction: column;
}
/deep/ .el-table__body-wrapper {
  order: 1
}
/deep/ .el-table--medium .el-table__footer-wrapper tr > td:not(:first-child) {
  padding: 10px 5px;
}

/deep/ .list.vxe-table--render-default.size--small .vxe-body--column.col--ellipsis {
  height: auto;
  .vxe-cell {
    max-height: inherit;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.col-yellow {
  background: yellow;
}
</style>
